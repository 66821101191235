function isBrowser() {
  return Boolean(typeof window !== 'undefined' && window.__ENV);
}

function getFiltered() {
  const prefix = 'REACT_APP';
  return Object.keys(process.env)
    .filter((key) => new RegExp(`^${prefix}_`, 'i').test(key))
    .reduce((env, key) => {
      env[key] = process.env[key];
      return env;
    }, {});
}

export default function env(key = '') {
  const prefix = 'REACT_APP';
  const safeKey = `${prefix}_${key}`;
  if (isBrowser()) {
    return key.length ? window.__ENV[safeKey] : window.__ENV;
  }
  return key.length ? process.env[safeKey] : getFiltered();
}
