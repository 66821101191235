import { TextField } from '@mui/material';
import { ComponentType, ReactNode } from 'react';

import { Controller, Control, FieldValues } from 'react-hook-form';

interface ControlledTextInputProps<T extends FieldValues> {
  control: Control<T>;
  label: string;
  name: string;
  type: string;
  controllerProps?: any;
  inputsProps?: any;
  errors?: any;
  isDirty?: boolean;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  endAdornment?: ReactNode;
  component?: ComponentType;
  fullWidth?: boolean;
  disabled?: boolean;
}

const ControlledTextInput = <T extends FieldValues>(
  props: ControlledTextInputProps<T>
) => {
  const Component = props.component || TextField;

  return (
    <Controller
      {...props.controllerProps}
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        return (
          <Component
            {...props.inputsProps}
            multiline={props.multiline}
            minRows={props.minRows}
            maxRows={props.maxRows}
            label={props.label}
            type={props.type}
            name={props.name}
            value={value}
            onChange={onChange}
            helperText={props.isDirty && props.errors && props.errors.message}
            error={props.isDirty && Boolean(props.errors)}
            InputProps={{
              endAdornment: props.endAdornment,
              inputProps: props.inputsProps,
            }}
            fullWidth={props.fullWidth}
            disabled={props.disabled}
          />
        );
      }}
    />
  );
};

export default ControlledTextInput;
