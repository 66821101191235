import {
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useRouter } from 'next/router';

interface ListItemMenuButtonInterface {
  text: string;
  path: string;
  icon: any;
}

const ListItemMenuButton = (props: ListItemMenuButtonInterface) => {
  const router = useRouter();
  const { pathname } = router;

  return (
    <MuiListItemButton
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 90,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        bgcolor: 'transparent',
        marginLeft: 2,
        marginRight: 2,
        marginTop: 2,
      }}
      onClick={() => {
        router.push(props.path);
      }}
      selected={pathname === props.path}
    >
      <ListItemIcon
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'flex-end',
          justifyContent: 'center',
          color: 'inherit',
        }}
      >
        {props.icon}
      </ListItemIcon>
      <ListItemText
        primary={props.text}
        disableTypography
        sx={{
          display: 'flex',
          flex: 1,
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        }}
      />
    </MuiListItemButton>
  );
};

export default ListItemMenuButton;
